import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import {HouseDoor, PersonCircle} from "react-bootstrap-icons";
export default function Profile() {
    const [global, dispatch] = useContext(GlobalContext);
    const [user, setUser] = useState({});

    useEffect(() => {

        handleLoadUser(global.user.id)

    }, [])

    const handleLoadUser = (user_id) => {
        const qryParams = '?pcl_id=' + global.pcl.id;
        Api.get(`client/users/${user_id}` + qryParams, (res) => {
            const data = res.data.data;
            setUser(data);
        })
    }

    return (
        <div className=''>
            {/* Basic info */}
            <div className='text-dark-light fs-22x fw-bold mb-1'>Profile Details</div>
            <div className='d-flex align-items-center mb-5'>
                <div style={{marginRight: '30px'}}>
                    <div className='d-flex justify-content-center align-items-center p-2'>
                        <PersonCircle className='fs-80x' style={{color: '#5eb388'}}/>
                    </div>
                    <div className='d-flex justify-content-center align-items-center fs-19x'>Basic Info</div>
                </div>
                <div className='flex-1'>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>Name:</span> {`${user.first_name} ${user.last_name}`}
                    </div>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>Contact:</span> {`${user.contact_no}`}</div>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>Email:</span> {`${user.email}`}</div>
                </div>
            </div>

            <div className='ms-4 mt-4' style={{borderTop: '2px solid #c5c5c5'}}></div>

            {/* Address info */}
            <div className='d-flex align-items-center mt-3'>
                <div style={{marginRight: '30px'}}>
                    <div className='d-flex justify-content-center align-items-center p-2'>
                        <HouseDoor className='fs-80x' style={{color: '#efa0a0'}}/>
                    </div>
                    <div className='d-flex justify-content-center align-items-center fs-19x'>Address</div>
                </div>
                <div className='flex-1'>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>City:</span> {(user.city) ? `${user.city}` : "---"}
                    </div>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>Address 1:</span> {(user.address1) ? `${user.address1}` : "---"}
                    </div>
                    <div className='profile-list'><span
                        className='text-dark-light fw-bold me-4'>Address 2:</span> {(user.address2) ? `${user.address2}` : "---"}
                    </div>
                </div>
            </div>
        </div>
    )
}