import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../_services/global-context";
import Api from "../_services/api_service";
import { Table, Card, Button, Alert } from "react-bootstrap";
import { ArrowUpRightCircleFill, Gear } from "react-bootstrap-icons";
import moment from "moment";

export default function Orders() {
    const [global, dispatch] = useContext(GlobalContext);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        handleLoadDetails();
    }, []);

    const handleLoadDetails = () => {
        const qryParams = `?pcl_id=${global.pcl.id}`;
        Api.get(`client/orders` + qryParams, (res) => {
            const data = res.data.data;
            setOrders(data);
        });
    };

    const handleManageBookings = (order_id) => {
        dispatch({ type: "ORDER-ID", payload: order_id });
        dispatch({ type: "ORDER-TIME", payload: moment().format("YYYY-MM-DD HH:mm") });
        window.location.assign(`/cart/update?order_id=${order_id}`)
    }

    const handleLoadOrder = (order_id) => {
        dispatch({ type: "ORDER-ID", payload: order_id });
        dispatch({ type: "ORDER-TIME", payload: moment().format("YYYY-MM-DD HH:mm") });
        window.location.assign("/cart");
    }

    return (
        <div>
            <div className='text-dark fs-4 fw-bold mb-4'>My Orders</div>

            {/* Description */}
            <Alert variant="info" className="mb-4">
                <p className="mb-0">
                    You can edit your future booking orders up to 7 days before the reservation date. 
                    Certain limitations apply, and modifications may not be available for some orders. 
                    Please call our customer service for assistance.
                </p>
            </Alert>

            {/* Table View for Larger Screens */}
            <Table responsive bordered hover className='d-none d-lg-table table-sm'>
                <thead className='bg-primary text-white'>
                    <tr>
                        <th>#</th>
                        <th>Order No</th>
                        <th>Details</th>
                        <th>Payment Status</th>
                        <th className="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => {
                        let p_class = "text-light bg-danger";
                        let payment_status = "No Payment";
                        if (order.payment_status === 1) {
                            p_class = "text-dark bg-warning";
                            payment_status = "Partial Paid";
                        } else if (order.payment_status === 2) {
                            p_class = "text-light bg-success";
                            payment_status = "Fully Paid";
                        }

                        return (
                            <tr key={order.id}>
                                <td>{index + 1}</td>
                                <td>{order.order_number}</td>
                                <td>{order.item_names}</td>
                                <td>
                                    <span className={`badge ${p_class} px-2 py-1`}>
                                        {payment_status}
                                    </span>
                                </td>
                                <td className="text-end">
                                    <Button variant="outline-info me-1" size="sm" onClick={() => handleManageBookings(order.id)}>
                                        <Gear /> Bookings
                                    </Button>

                                    {order.total_enable_rsvps >= 1 && (
                                        <a href={`/rsvp?order-id=${order.id}`} className='btn btn-sm btn-outline-primary me-1'><Gear /> RSVP</a>
                                    )}
                                    <Button variant="outline-success" size="sm" onClick={() => handleLoadOrder(order.id)}>
                                        <ArrowUpRightCircleFill className="me-1" />Open
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    {orders.length === 0 && (
                        <tr>
                            <td colSpan={5} className="text-center text-muted">
                                No orders found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Card View for Smaller Screens */}
            <div className='d-lg-none'>
                {orders.map((order, index) => {
                    let p_class = "text-light bg-danger";
                    let payment_status = "No Payment";
                    if (order.payment_status === 1) {
                        p_class = "text-dark bg-warning";
                        payment_status = "Partial Paid";
                    } else if (order.payment_status === 2) {
                        p_class = "text-light bg-success";
                        payment_status = "Fully Paid";
                    }

                    return (
                        <Card key={order.id} className="mb-3 shadow-sm">
                            <Card.Body>
                                <Card.Title className="fw-bold">Order #{order.order_number}</Card.Title>
                                <Card.Text className="mb-2 text-muted">
                                    <strong>Details:</strong> {order.item_names}
                                </Card.Text>
                                <Card.Text className="mb-2">
                                    <strong>Payment Status:</strong>{" "}
                                    <span className={`badge ${p_class} px-2 py-1`}>
                                        {payment_status}
                                    </span>
                                </Card.Text>
                                <div className="d-flex gap-2">
                                    {order.total_enable_rsvps >= 1 && (
                                        <a
                                            href={`/rsvp?order-id=${order.id}`}
                                            className='btn btn-sm btn-outline-primary flex-fill'
                                        >
                                            <Gear /> RSVP
                                        </a>
                                    )}
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        className="flex-fill"
                                        onClick={() => handleLoadOrder(order.id)}
                                    >
                                        <ArrowUpRightCircleFill className="me-1" />
                                        Open
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    );
                })}
                {orders.length === 0 && (
                    <div className="text-center text-muted">No orders found.</div>
                )}
            </div>
        </div>
    );
}
