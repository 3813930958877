import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import {Button, Col, Form, Modal, Row, Table, Card} from "react-bootstrap";
import Swal from "sweetalert2";
import Api from "../_services/api_service";
import Select from "react-select";
import {Envelope, Pencil, Plus, Repeat, Trash3, Clock, CheckCircle, XCircle, DashCircle} from "react-bootstrap-icons";
import URL from "../_services/routes";
import moment from "moment";
import Services from "../_services/services";

export default function Rsvp() {
    const [global, dispatch] = useContext(GlobalContext);
    const [rsvps, setRsvps] = useState([]);
    const [itemId, setItemId ] = useState('');
    const [rsvpEdit, setRsvpEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [rsvpId, setRsvpId] = useState(null);
    const [rsvpModal, setRsvpModal] = useState(false);

    const [booking, setBooking] = useState({});
    const [bookings, setBookings] = useState([]);
    const [selectBookings, setSelectBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState({});

    const [admissions, setAdmissions] = useState([]);
    const [selectedAdmission, setSelectedAdmission] = useState({});
    const [admissionOptions, setAdmissionOptions] = useState([]);

    const rsvp_default = {email: '', contact_no: '', description: '', status: 1}
    const rsvp_member_default = {first_name: '', last_name: '', admission_id: '', status: 1}
    const [rsvp, setRsvp] = useState(rsvp_default);
    const [rsvpMembers, setRsvpMembers] = useState([rsvp_member_default]);


    const order_id = URL.urlQueryParams('order-id')
    const [orderId, setOrderId] = useState(order_id || 0);

    // Effects
    useEffect(() => {
        handleLoadBookings();
    }, [])

    useEffect(() => {
        if (selectedBooking.value) {
            handleLoadRsvps();

            const booking = bookings.find(obj => obj.id == selectedBooking.value);
            if (booking && booking.item_id) {
                handleLoadAdmissions(booking.item_id);
            }
        }

    }, [selectedBooking])

    // Load Rsvp data
    function handleLoadRsvps() {
        const booking_id = selectedBooking.value || '';
        const qryParams = '?pcl_id=' + global.pcl.id + "&booking_id=" + booking_id + "&request_type=list"
        Api.get(`client/rsvps` + qryParams, (res) => {
            const data = res.data.data
            setRsvps(data)
        });
    }

    function handleLoadBookings() {
        const qryParams = '?pcl_id=' + global.pcl.id + "&order_id=" + orderId + "&booking_type=list"
        Api.get(`client/bookings` + qryParams, (res) => {
            const data = res.data.data;
            setBookings([...data])

            const options = [];
            data.map((booking, index) => {
                if (!booking.enable_rsvp) return null;
                const booking_date = moment(booking.booking_date).format('DD-MM-YYYY')
                const option = {value: booking.id, label: ' (' + booking_date + ') ' + booking.item_name}
                options.push(option);
            });

            if (!selectedBooking.value) {
                setSelectedBooking({...options[0]});
            }

            setSelectBookings([...options]);
        });
    }

    function handleLoadAdmissions(item_id) {
        const qryParams = '?pcl_id=' + global.pcl.id + "&item_id=" + item_id + ""
        Api.get(`client/item-admissions` + qryParams, (res) => {
            const data = res.data.data
            setAdmissions([...data])

            const options = [];
            data.map(admission => {
                const option = {value: admission.id, label: admission.name}
                options.push(option)
            });

            setAdmissionOptions([...options])
        });
    }

    const handleErrors = (errors, message) => {
        setErrors(errors);
        dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
    }

    const handleSetDefaultValues = () => {
        setRsvp(rsvp_default)
    }

    function handleGetDetails(rsvp_id) {
        const qryParams = '?pcl_id=' + global.pcl.id;
        Api.get(`client/rsvps/${rsvp_id}` + qryParams, (res) => {
            const data = res.data.data
            if (data && data.members) {
                setRsvpMembers(data.members)
                setRsvpEdit(true)
            }
            setRsvp(data);
        });
    }

    const handleChange = (fieldName, value, index = null) => {
        if (index !== null) {
            if (fieldName == 'admission_id') {
                value = value.value;
            }

            rsvpMembers[index][fieldName] = value
            setRsvpMembers([...rsvpMembers])
        }else {
            if (fieldName == 'booking_id') {
                setSelectedBooking(value)
                value = value.value;
            }
            rsvp[fieldName] = value
            setRsvp({...rsvp})
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        rsvp['pcl_id'] = global.pcl.id;
        rsvp['booking_id'] = selectedBooking.value || ''
        rsvp['members'] = rsvpMembers;

        Api.post(`client/rsvps`, rsvp,
            (res) => {
                setRsvpEdit(false);
                setRsvp(rsvp_default)
                setRsvps(res.data.data)
                dispatch({type: "SHOW-SUCCESS", payload: "Item rsvp added successfully"})
            },
            (errors) => {
                handleErrors(errors, "Error: " + JSON.stringify(errors))
            });
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()
        rsvp['pcl_id'] = global.pcl.id
        rsvp['booking_id'] = selectedBooking.value || ''
        rsvp['members'] = rsvpMembers;

        Api.put(`client/rsvps/${rsvp['id']}`, rsvp,
            (res) => {
                setRsvps(res.data.data)
                setRsvp(rsvp_default)
                setRsvpEdit(false)
                dispatch({type: "SHOW-SUCCESS", payload: "Rsvp updated successfully"})
            },
            (errors) => {
                handleErrors(errors, "Error: " + JSON.stringify(errors))
            });
    }

    const handleDelete = (rsvp_id) => {

        const params = {
            pcl_id: global.pcl.id,
            booking_id: selectedBooking.value || ''
        }

        Swal.fire({
            title: 'Alert?',
            text: "Are to sure to delete rsvp!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.delete(`client/rsvps/${rsvp_id}`, {data: params}, (res) => {
                        const data = res.data.data
                        setRsvps(data)
                        dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                    }, (errors) => {
                        dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
                    })
            }
        })

    }

    const handleAddMember = (index) => {
        rsvpMembers.push(rsvp_member_default)
        setRsvpMembers([...rsvpMembers])
    }

    const handleRemoveMember = (index, member_id) => {
        if (member_id) {
            rsvpMembers[index]['status'] = 5
        }else {
            rsvpMembers.splice(index, 1)
        }

        setRsvpMembers([...rsvpMembers])
    }

    const handleInvitationEmail = (rsvp_id) => {
        Swal.fire({
            title: 'Alert?',
            text: "Are to send rsvp invitation email!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendInvitationMail(rsvp_id)
            }
        })
    }

    const handleSendInvitationMail = (rsvp_id) => {
        const mail = {'pcl_id': global.pcl.id}
        Api.post(`client/rsvps/${rsvp_id}/email`, mail, (res) => {
            const data = res.data.data
            dispatch({type: "SHOW-SUCCESS", payload: "Invitation email sent successfully"})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        });
    }

    const targetHeight = 31
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    const renderRSVPRow = (rsvp, index) => {
        let status = '';

        switch (rsvp.status) {
            case 2:
                status = 'Invitation sent';
                break;
            case 3:
                status = 'Invitation accepted';
                break;
            case 4:
                status = 'Invitation not accepted';
                break;
            default:
                status = 'Email not sent';
        }

        return (
            <div key={rsvp.id} className="mb-3">
                <Card>
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <strong>RSVP #{index + 1}</strong>
                            </div>
                            <div>
                                <Button
                                    type="button"
                                    size="sm"
                                    variant="outline-success"
                                    className="me-1"
                                    onClick={() => handleGetDetails(rsvp.id)}
                                >
                                    <Pencil />
                                </Button>
                                <Button
                                    type="submit"
                                    size="sm"
                                    variant="outline-danger"
                                    className="me-1"
                                    onClick={() => handleDelete(rsvp.id)}
                                >
                                    <Trash3 />
                                </Button>
                                <Button
                                    type="button"
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() => handleInvitationEmail(rsvp.id)}
                                >
                                    <Envelope />
                                </Button>
                            </div>
                        </div>
                        <div className="mb-2">
                            <strong>Email:</strong> {rsvp.email}
                        </div>
                        <div className="mb-2">
                            <strong>Members:</strong>{' '}
                            {rsvp.members.map((member, idx) => {
                                let memberStatus = member.status === 2
                                    ? 'text-success'
                                    : member.status === 3
                                    ? 'text-danger'
                                    : '';

                                return (
                                    <span key={idx} className={`${memberStatus} me-2`}>
                                        {member.first_name} {member.last_name},
                                    </span>
                                );
                            })}
                        </div>
                        <div>
                            <strong>Status:</strong> {status}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    };    
    // console.log(bookingsList)
    return (
        <div>
            <div className="container py-4">
                {/* Heading */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="text-primary">Manage RSVP List</h2>
                    <Button 
                        variant="secondary" 
                        className='me-2'
                        size='sm' 
                        onClick={handleLoadRsvps}>
                        <Repeat /><span className="d-none d-md-inline">&nbsp;Refresh List</span>
                    </Button>
                </div>

                {/* Description */}
                <p className="text-muted">
                    Manage the RSVP list for your event. Use this page to add, edit, delete, or send invitations. You can track the
                    status of invitations, and you'll receive an alert email when someone responds.
                </p>

                    

                {/* Booking Selector */}
                <Row className="align-items-center mb-4">
                    <Col sm="auto">
                        <Form.Label>Select Booking:</Form.Label>
                    </Col>
                    <Col sm="auto" className="mb-2">
                        <Select
                            options={selectBookings}
                            value={selectedBooking}
                            onChange={(value) => setSelectedBooking(value)}
                            styles={selectStyles}
                            placeholder="Select a booking"
                        />
                    </Col>
                </Row>
                <div>
                    {/* Counts */}
                    <div className="text-secondary">
                        Guests Allowed: 30
                    </div>
                    <div className="text-secondary">
                        Invitation Sent: 4
                    </div>
                    <div className="text-secondary">
                        Attending: 2
                    </div>
                    <div className="text-secondary">
                        Waiting for Response: 2
                    </div>
                </div>


            </div>

            {/* Item rsvps update and create form */}
            {rsvpEdit &&
                <Form autoComplete="off" onSubmit={(rsvp.id) ? handleEditSubmit : handleSubmit}>
                    <div className='m-auto mt-2' style={{width: '70%'}}>
                        <Row>
                            <Col sm={6}>
                                <div className='d-flex gap-2'>
                                    <Form.Label className='mb-0'>Rsvp Invitation Email:</Form.Label>
                                    <div className='flex-1'>
                                        <Form.Control size='sm' type="email"
                                                      className={`ms-1 ${errors.email} && 'is-invalid'`}
                                                      defaultValue={rsvp.email}
                                                      onChange={(e) => handleChange('email', e.target.value)} required/>
                                        <div className="invalid-feedback">{errors.email}</div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className='d-flex gap-2 mb-2'>
                                    <Form.Label className='mb-0'>Contact No:</Form.Label>
                                    <div className='flex-1'>
                                        <Form.Control size='sm' type="text"
                                                      className={(errors.contact_no) && 'is-invalid'}
                                                      defaultValue={rsvp.contact_no}
                                                      onChange={(e) => handleChange('contact_no', e.target.value)}
                                                      required/>
                                        <div className="invalid-feedback">{errors.contact_no}</div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12}>
                                <div className='d-flex gap-2 mb-2'>
                                    <Form.Label className='mb-0 me-2'>Email Subject:</Form.Label>
                                    <div className='flex-1 ms-5'>
                                        <Form.Control size='sm' type='text'
                                                      className={`${errors.subject && 'is-invalid'}`}
                                                      defaultValue={rsvp.subject}
                                                      onChange={(e) => handleChange('subject', e.target.value)}/>
                                        <div className="invalid-feedback">{errors.subject}</div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={12}>
                                <div className='d-flex gap-2 mb-2'>
                                    <Form.Label className='mb-0 me-1'>Custom Message:</Form.Label>
                                    <div className='flex-1 ms-4'>
                                        <Form.Control size='sm' as="textarea" rows={2}
                                                      className={`${errors.custom_message && 'is-invalid'}`}
                                                      defaultValue={rsvp.custom_message}
                                                      onChange={(e) => handleChange('custom_message', e.target.value)}/>
                                        <div className="invalid-feedback">{errors.custom_message}</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='m-auto' style={{width: '90%'}}>
                        <div className='border-bottom mb-2'></div>

                        {rsvpMembers.map((member, index) => {
                            const m_admission = admissionOptions.find(obj => obj.value === member.admission_id);

                            if (member.status !== 5) {
                                return (
                                    <div className='d-flex gap-3 mb-3'>
                                        <div className='flex-1'>
                                            <div className='d-flex gap-2'>
                                                <div className=''>
                                                    <span style={{backgroundColor: '#dedede', padding: '3px 8px', borderRadius: '15px', fontSize: '13px'}}>
                                                        {index + 1}
                                                    </span>
                                                </div>

                                                <Form.Label className='mb-0'>First Name:</Form.Label>
                                                <div className='flex-1'>
                                                    <Form.Control size='sm' type="text"
                                                                  className={(errors.first_name) && 'is-invalid'}
                                                                  defaultValue={member.first_name}
                                                                  onChange={(e) => handleChange('first_name', e.target.value, index)}
                                                                  required/>
                                                    <div className="invalid-feedback">{errors.first_name}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex-1'>
                                            <div className='d-flex gap-2'>
                                                <Form.Label className='mb-0'>Last Name:</Form.Label>
                                                <div className='flex-1'>
                                                    <Form.Control size='sm' type="text"
                                                                  className={(errors.last_name) && 'is-invalid'}
                                                                  defaultValue={member.last_name}
                                                                  onChange={(e) => handleChange('last_name', e.target.value, index)}
                                                                  required/>
                                                    <div className="invalid-feedback">{errors.last_name}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex-1'>
                                            <div className='d-flex gap-2'>
                                                <Form.Label className='mb-0'>Select Age Group:</Form.Label>
                                                <div className='flex-1'>
                                                    <Select className={(errors.admission_id) && 'is-invalid'} styles={selectStyles}
                                                            value={m_admission} options={admissionOptions}
                                                            onChange={(e) => handleChange('admission_id', e, index)}
                                                            required/>
                                                    <div className="invalid-feedback">{errors.admission_id}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{width: '86px'}}>
                                            {(index == 0) ?
                                                <button type='button' className='btn btn-success btn-xs me-1'
                                                        onClick={(e) => handleAddMember(e)}>
                                                    <Plus className='fs-17x'/> Member
                                                </button>
                                                :
                                                <button type='button' className='btn btn-danger btn-xs me-1'
                                                        onClick={() => handleRemoveMember(index, member.id || null)}>
                                                    <Trash3/>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}

                        <div className='text-end'>
                            <Button size='sm' type="submit" variant="primary" className='me-2'>{(rsvp.id) ? 'Save Changes' : 'Add Rsvp'}</Button>
                            <Button size='sm' type="button" variant="warning" onClick={() => {
                                setRsvpMembers([rsvp_member_default])
                                setRsvp(rsvp_default)
                                setRsvpEdit(false)
                            }}>Cancel</Button>
                        </div>

                    </div>
                </Form>
            }

            {(!rsvpEdit) &&
                <>
                    <div className="text-end mt-2 mt-sm-0 mb-4">
                        {!rsvpEdit && (
                            <Button
                                variant="primary"
                                className='me-2'
                                size='sm'
                                onClick={() => {
                                    setRsvpEdit(true);
                                    setRsvp({ email: "", contact_no: "", subject: "", custom_message: "", members: [] });
                                    setRsvpMembers([{ first_name: "", last_name: "", admission_id: "", status: 1 }]);
                                }}
                            >
                                <Plus /> Add RSVP
                            </Button>
                        )}
                    </div>

                    <div className="d-lg-none">
                        {rsvps.length > 0 ? (
                            rsvps.map((rsvp, index) => renderRSVPRow(rsvp, index))
                        ) : (
                            <div className="text-center">No RSVP found!</div>
                        )}
                    </div>

                    
                
                    <Table className='table table-hover table-bordered d-none d-lg-table fs-6 mt-3 mb-5'>
                        <thead className='bg-primary text-white'>
                            <tr>
                                <th>#</th>
                                <th>RSVP ID</th>
                                <th>Email</th>
                                <th>Members</th>
                                <th>Invitation Status</th>
                                <th className='text-end'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rsvps.map((rsvp, index) => {
                                let status = '';

                                switch (rsvp.status) {
                                    case 2:
                                        status = 'Invitation sent';
                                        break;
                                    case 3:
                                        status = 'Invitation accepted';
                                        break;
                                    case 4:
                                        status = 'Invitation not accepted';
                                        break;
                                    default:
                                        status = 'Email not sent';
                                }

                                return (
                                    <tr key={rsvp.id} className="align-middle">
                                        <td>{index + 1}</td>
                                        <td>{rsvp.rsvp_id}</td>
                                        <td>{rsvp.email}</td>
                                        <td>
                                            {rsvp.members.map((member, memberIndex) => {
                                                let memberStatusClass = '';
                                                let memberIcon = '';

                                                switch (rsvp.status) {
                                                    case 1: // Email not sent
                                                        memberStatusClass = 'text-dark'; // Black color
                                                        memberIcon = <Envelope className="me-1" />;
                                                        break;
                                                    case 2:
                                                        if (member.status === 1) {
                                                            memberStatusClass = 'text-warning'; // Yellow
                                                            memberIcon = <Clock className="me-1" />;
                                                        } else if (member.status === 2) {
                                                            memberStatusClass = 'text-success'; // Green
                                                            memberIcon = <CheckCircle className="me-1" />;
                                                        } else {
                                                            memberStatusClass = 'text-danger'; // Red
                                                            memberIcon = <XCircle className="me-1" />;
                                                        }
                                                        break;
                                                    case 3: // Accepted
                                                        memberStatusClass = 'text-success'; // Green
                                                        memberIcon = <CheckCircle className="me-1" />;
                                                        break;
                                                    case 4: // Rejected
                                                        memberStatusClass = 'text-danger'; // Red
                                                        memberIcon = <XCircle className="me-1" />;
                                                        break;
                                                    default:
                                                        memberStatusClass = 'text-muted'; // Default muted color
                                                        memberIcon = <DashCircle className="me-1" />;
                                                        break;
                                                }

                                                return (
                                                    <span key={memberIndex} className={`${memberStatusClass} me-3`}>
                                                        {memberIcon}
                                                        {member.first_name} {member.last_name} {rsvp.status},{member.status}
                                                    </span>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            <span className={status === 'Invitation accepted' ? 'text-success' : status === 'Invitation not accepted' ? 'text-danger' : 'text-muted'}>
                                                {status}
                                            </span>
                                        </td>
                                        <td className="text-end">
                                            <Button
                                                type='button'
                                                className='me-2'
                                                size='sm'
                                                variant='outline-success'
                                                onClick={() => handleGetDetails(rsvp.id)}
                                            >
                                                <Pencil className="me-2" /> Edit
                                            </Button>

                                            <Button
                                                type='button'
                                                className='me-2'
                                                size='sm'
                                                variant='outline-danger'
                                                onClick={() => handleDelete(rsvp.id)}
                                            >
                                                <Trash3 className="me-2" /> Delete
                                            </Button>

                                            <Button
                                                type='button'
                                                size='sm'
                                                variant='outline-primary'
                                                onClick={() => handleInvitationEmail(rsvp.id)}
                                            >
                                                <Envelope className="me-2" /> Send Email
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}

                            {rsvps.length === 0 && (
                                <tr>
                                    <td colSpan={6} className='text-center align-middle text-muted'>
                                        <span>No RSVP found!</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>


                </>
            }


            {/*  RSVP modal  */}
            <Modal show={rsvpModal} animation={false} size="xl">
                <Modal.Header>
                    <Modal.Title>Manage RSVP</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => setRsvpModal(false)}></button>
                </Modal.Header>

                <Modal.Body>
                    {/*    */}
                </Modal.Body>
            </Modal>

        </div>
    )
}
