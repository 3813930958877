import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../_services/global-context";
import { Alert, Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import URL from "../_services/routes";

export default function RsvpResponse() {
    const [global, dispatch] = useContext(GlobalContext);

    const rsvp_email = URL.urlQueryParams("email");
    const rsvp_response = URL.urlQueryParams("response");
    const [signWaiver, setSignWaiver] = useState(false);
    const [rsvpEmail, setRsvpEmail] = useState(rsvp_email || "");
    const [response, setResponse] = useState(rsvp_response || 0);

    const rsvp_id_qry = URL.urlQueryParams("rsvp_id");
    const [rsvpId, setRsvpId] = useState(rsvp_id_qry || "");

    const navigate = useNavigate();
    const location = useLocation();
    const res = URL.urlQueryParams("response");

    // Effects
    useEffect(() => {
        if (res && res.trim() !== "") {
            const msg = "Your response has been saved successfully!";
            setResponse(msg);
            dispatch({ type: "SHOW-SUCCESS", payload: msg });
        }

        setTimeout(() => {
            setResponse(null);
            removeQueryParam("response");
        }, 5000);
    }, []);

    const removeQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        navigate(
            {
                pathname: location.pathname,
                search: searchParams.toString(),
            },
            { replace: true }
        );
    };

    return (
        <div style={{ background: "#f8f9fa", minHeight: "100vh", padding: "2rem" }}>
            <Container>
                {(res && response) && (
                    <Alert variant="success" className="text-center">
                        <h5 className="fw-bold text-success mb-0">{response}</h5>
                    </Alert>
                )}

                <div
                    style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        padding: "2rem",
                        border: "1px solid #ddd", // Subtle border instead of shadow
                    }}
                >
                    <div className="text-center">
                        <h3 style={{ fontWeight: "bold", color: "#343a40" }}>
                            Thank You for Your RSVP!
                        </h3>
                        <p className="text-muted">
                            We're thrilled to have you join us for this special event!
                        </p>
                    </div>

                    <div className="mt-4">
                        <h5 style={{ fontWeight: "bold", color: "#495057" }}>
                            Avoid Delays on Event Day
                        </h5>
                        <p className="text-muted">
                            To ensure a smooth experience and minimize waiting times in lineups, we
                            kindly request you to sign the event waiver in advance. This will help
                            us prepare everything for your arrival and ensure you can enjoy the
                            event without interruptions.
                        </p>
                    </div>

                    <div className="text-center mt-4">
                        <Button
                            href={`/waiver-sign?rsvp_id=${rsvpId}`}
                            variant="primary"
                            size="lg"
                            className="shadow-sm"
                            style={{
                                minWidth: "200px",
                                fontWeight: "bold",
                                background: "linear-gradient(135deg, #007bff, #0056b3)",
                                border: "none",
                            }}
                        >
                            Proceed to Sign Waiver
                        </Button>
                    </div>

                    <div className="mt-4 text-center">
                        <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                            Signing the waiver in advance helps us serve you better on the event
                            day. Thank you for your cooperation!
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}
