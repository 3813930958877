import React, {useContext, useEffect, useState} from "react";
import URLr from "../_services/routes";
import Api from "../_services/api_service";
import axios from "axios";
import {GlobalContext} from "../_services/global-context";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import Services from "../_services/services";
import {CalendarMinus, PencilSquare, Plus, XCircleFill} from "react-bootstrap-icons";
import {cloneDeep} from "lodash";
import room_default_img from "../../images/product-dummy-image-1.jpg";

import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";


export default function Bookings({itemId, orderDetailId, bookings, setBookings}) {
    const [global, dispatch] = useContext(GlobalContext);
    const [property, setProperty] = useState({});
    const [bookableService, setBookableService] = useState({});

    const [item, setItem] = useState({})

    const [orderId, setOrderId] = useState(global.order_id || null)
    const [properties, setProperties] = useState([])
    const [bookingsData, setBookingsData] = useState([]);
    const [bookingFromDate, setBookingFromDate] = useState(null);

    const [bookableProperties, setBookableProperties] = useState([]);
    const [preSelectedBooking, setPreSelectedBooking] = useState({});

    const [admissions, setAdmissions] = useState([])
    const [bookingErrors, setBookingErrors] = useState([]);
    const [slotSearchTerms, setSlotSearchTerms] = useState({pcl_id: global.pcl.id})

    const b_details_default = {
        max_bookings: 0,
        max_bookings_per_day: 1,
        max_checkins: 1,
        max_bookings_required: 0,
        slots_auto_selection: 0
    }
    const [bookingDetails, setBookingDetails] = useState(b_details_default)
    const [availableDates, setAvailableDates] = useState([])

    // Effects
    useEffect(() => {
        console.log('Booking-component-call: ', itemId, item);
        if (itemId && (!item || item?.id != itemId)) {
            handleGetItemDetails(itemId);
            handleGetAdmissions(itemId);

            handleCheckAvailability();
        }

        console.log('orderDetailId: ', orderDetailId)
        if (orderDetailId) {
            console.log('handleCartEditDetails: IF')
            handleCartEditDetails(itemId, orderDetailId)
        } else {
            console.log('handleGetProperties: ELSE')
            handleGetProperties(itemId)
        }
    }, [itemId, orderDetailId])

    useEffect(() => {
        if (orderDetailId && !global.order_id) {
            dispatch({type: "SHOW-ERROR", payload: 'Sorry your order has been expired.'});
            setTimeout(() => {
                window.location.assign('/');
            }, 3000);
        }
    }, [global.order_id])

    useEffect(() => {
        if (orderDetailId) {
            if (bookings.length === 0 && bookableProperties.length > 0 && admissions.length > 0) {
                let bookings_arr = handleArrangeBookingData(bookableProperties, admissions, bookingsData)
                if (bookings_arr.length >= 1 && bookings_arr[0].id && bookings_arr[0].booking_date != '') {
                    bookings_arr = handleSetBookingSlots(bookings_arr)
                } else {
                    setBookings([...bookings_arr])
                }
            }
        } else {
            if (bookings.length === 0 && bookableProperties.length > 0 && admissions.length > 0) {
                handleSetBooking(false)
            }
        }

    }, [bookableProperties, admissions, bookingsData])


    const handleGetItemDetails = (item_id) => {
        const params = "name,event_id,max_bookings,max_bookings_per_day,max_bookings_required,max_checkins,advance_booking_days,slots_auto_selection,booking_confirmations,description_short";
        let qryParams = {
            pcl_id: global.pcl.id,
            params: params,
        }

        const qParams = '?' + new URLSearchParams(qryParams).toString();
        Api.get(`client/items/${item_id}` + qParams, (res) => {
            const data = res.data.data
            bookingDetails['max_bookings'] = data.max_bookings || 0;
            bookingDetails['max_checkins'] = data.max_checkins || 1;
            bookingDetails['max_bookings_per_day'] = data.max_bookings_per_day || 1;
            bookingDetails['max_bookings_required'] = data.max_bookings_required || 0;
            bookingDetails['slots_auto_selection'] = data.slots_auto_selection || 0;
            bookingDetails['advance_booking_days'] = data.advance_booking_days || 0;
            setItem(data)
            setBookingDetails({...bookingDetails})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleGetAdmissions = (item_id) => {
        let qryParams = `?pcl_id=${global.pcl.id}&item_id=${item_id}`;

        Api.get(`client/item-admissions` + qryParams, (res) => {
            const data = res.data.data
            setAdmissions([...data])
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleGetProperties = (item_id) => {
        let qryParams = `?pcl_id=${global.pcl.id}&item_id=${item_id}`;

        Api.get(`client/item-properties` + qryParams, (res) => {
            const data = res.data.data
            // set bookable properties
            const bProperties = handleSetBookableProperties(data)
            setProperties(data)
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    // Get cart update properties
    const handleCartEditDetails = (item_id, order_detail_id) => {
        // e.preventDefault();
        const params = '?pcl_id=' + global.pcl.id + '&item_id=' + item_id + '&order_id=' + orderId || '';
        Api.get(`client/item-properties/${order_detail_id}` + params, (res) => {
            const data = res.data.data

            const bookings_data = data.bookings
            setBookingsData([...bookings_data])
            const properties_arr = handleSetPropertiesOrder(data.properties);
            setProperties(properties_arr)
            const bProperties = handleSetBookableProperties(properties_arr)

            const from_date = data.properties.find(obj => obj.slug == 'from_date')
            const max_bookings = data.properties.find(obj => obj.slug == 'max-bookings')
            const adm_availability = data.properties.find(obj => obj.slug == 'admissions-availability')
            if (max_bookings && max_bookings.cart_value) {
                bookingDetails['max_bookings'] = max_bookings.cart_value.property_value || max_bookings.cart_value
            }

            if (adm_availability && adm_availability.cart_value) {
                if (adm_availability && adm_availability.value && adm_availability.property_values) {
                    const property_value = adm_availability.property_values.find(obj => obj.id == adm_availability.value)
                    if (property_value) {
                        let avail_data = property_value.value
                        avail_data = avail_data.split(',');

                        let error = false;
                        if (avail_data.length == 2 || avail_data.length == 4) {
                            const avail_weeks = avail_data.map((week) => week.trim());
                            avail_weeks.map((week, index) => {
                                error = false
                                if (index == 2 || index == 3) {
                                    week = avail_weeks[0] + ' ' + week
                                }

                                const parsedDateTime = moment(week, undefined, true);
                                const valid = parsedDateTime.isValid();
                                if (!valid) {
                                    error = true;
                                }
                            })

                            if (!error) {
                                if (avail_data[0] && avail_data[1]) {
                                    bookingDetails['from_date'] = avail_data[0]
                                    bookingDetails['to_date'] = avail_data[1]
                                }

                                if (avail_data[2] && avail_data[3]) {
                                    bookingDetails['from_time'] = avail_data[2]
                                    bookingDetails['to_time'] = avail_data[3]
                                }

                            }
                        }
                    }
                }
            }

            setBookingDetails({...bookingDetails})

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Check availability of dates for to show in calendar
    const handleCheckAvailability = (selected_date = null) => {
        let qryParams = {
            pcl_id: global.pcl.id,
            item_id: itemId,
            selected_date: (selected_date) ? moment(selected_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        }

        if (bookingDetails && bookingDetails.from_date) {
            qryParams['from_date'] = bookingDetails.from_date

        }

        if (bookingDetails && bookingDetails.to_date) {
            qryParams['to_date'] = bookingDetails.to_date
        }


        const qParams = '?' + new URLSearchParams(qryParams).toString();
        Api.get(`client/availabilities` + qParams, (res) => {
            const data = res.data.data
            setAvailableDates([...data])
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    // Check is valid bookings
    const isValidBooking = (booking) => {
        let error = null;
        const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
        if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
            error = 'Select valid booking date';
        }

        error = (error || !booking.bookings || booking.bookings.length === 0);
        for (const subBooking of booking.bookings) {
            if (!subBooking.bookable_service_id) {
                error = 'Bookable service ID is missing';
            }
            if (!subBooking.from_time) {
                error = 'From time is missing';
            }
            if (!subBooking.to_time) {
                error = 'To time is missing';
            }
        }

        return (!error);
    }

    // Count valid bookings count
    const validateBookingsCount = (bookings_arr) => {
        let validCount = 0;
        for (const booking of bookings_arr) {
            let error = null;
            const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
            if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
                error = 'Add valid booking date';
            }

            for (const subBooking of booking.bookings) {
                if (!subBooking.bookable_service_id) {
                    error = 'Bookable service ID is missing';
                }
                if (!subBooking.from_time) {
                    error = 'From time is missing';
                }
                if (!subBooking.to_time) {
                    error = 'To time is missing';
                }
            }

            if (!error) validCount++;
        }

        return validCount;
    }

    const handleBookStatusChange = (bIndex, value) => {
        bookings[bIndex]['status'] = value
        bookings[bIndex]['change'] = true
        setBookings([...bookings])
    }

    const handleImageChange = (e, index) => {
        properties[index]['image_url'] = URL.createObjectURL(e.target.files[0])
        properties[index]['value'] = e.target.files[0]
        setProperties([...properties])
    }

    // Arrange booking date
    const handleArrangeBookingData = (iProperties, admissions_arr, bookings_data = []) => {

        let res_bookings = []
        if (bookings_data.length === 0) {
            const bProperties = [];
            iProperties.map((p) => {
                if (p.property_type == 'bookable-services') {
                    let row = {
                        property_id: p.id, name: p.name, bookable_service_id: null,
                        booking_date: '', from_time: null, to_time: null,
                        status: 1, change: false
                    }

                    bProperties.push(row)
                }
            });

            const bRow = {
                booking_date: '',
                admissions: admissions_arr,
                bookings: bProperties,
                slots: [],
                status: 1,
                selected: true
            }
            res_bookings = [bRow]
        } else {
            let bookings_copy = [];

            const bookings_arr = [];
            bookings_data.map((booking) => {
                // make bookable rows to show in table
                const bFound = bookings_arr.findIndex(obj => obj.id == booking.booking_id)
                if (bFound == -1) {
                    const bRow = {
                        id: booking.booking_id,
                        booking_date: booking.booking_date,
                        slots: [],
                        bookings: [],
                        admissions: admissions_arr,
                        status: booking.status,
                    }

                    bookings_arr.push(bRow)
                }

                // arrange booking data
                const fIndex = bookings_copy.findIndex(obj => obj.booking_id == booking.booking_id &&
                    obj.property_id == booking.property_id)

                if (fIndex == -1) {
                    bookings_copy.push(booking)
                }
            })

            iProperties.map((property, idx) => {
                const property_id = property.id
                bookings_arr.map((booking, index) => {
                    const fBooking = bookings_copy.find(obj => obj.booking_id == booking.id && obj.property_id == property_id)

                    const rBookings = bookings_arr[index]['bookings']
                    const fIndex = rBookings.findIndex(obj => obj.property_id == property_id)
                    if (fBooking && fIndex == -1) {
                        fBooking['name'] = property.name
                        fBooking['from_time'] = moment(fBooking['from_time'], 'HH:mm:ss').format('HH:mm')
                        fBooking['to_time'] = moment(fBooking['to_time'], 'HH:mm:ss').format('HH:mm')
                        rBookings.push(fBooking)
                        bookings_arr[index]['bookings'] = rBookings;
                    }
                })
            })

            // Set booking admissions
            bookings_arr.map((booking, index) => {
                const admissions_data = booking.admissions;

                const admissions_list = admissions_data.map((admission, idx) => {
                    const fBooking = bookings_data.find(obj => obj.booking_id == booking.id && obj.admission_id == admission.id);
                    if (fBooking) {
                        // Create a new admission object with updated requested_persons value
                        const updatedAdmission = {...admission, requested_persons: fBooking.expected_guests};
                        return updatedAdmission;
                    }
                });
                bookings_arr[index]['admissions'] = admissions_list;
            });

            res_bookings = bookings_arr
        }

        const sort_bookings = handleSortBookings(res_bookings)
        return sort_bookings;
    }

    const handleSetBookingSlots = async (bookings_arr) => {
        let res_bookings = []
        for (const [index, booking] of bookings_arr.entries()) {
            const b_bookings = booking.bookings;

            for (const [b_index, b_booking] of b_bookings.entries()) {
                const params = {
                    pcl_id: global.pcl.id,
                    booking_id: b_booking.booking_id || '',
                    property_id: b_booking.property_id,
                    bookable_service_id: b_booking.bookable_service_id,
                    booking_date: booking.booking_date
                }

                let slot_found = false;
                let apiUrl = Api.getApiURL();
                const qParams = "?" + new URLSearchParams(params).toString();

                // Request to get spaces slots
                const res = await axios.get(apiUrl + "client/bookable-service-times" + qParams);
                const sData = res.data.data;
                sData['property_id'] = b_booking.property_id;
                const b_slots = bookings_arr[index].slots || []

                const bsIndex = b_slots.findIndex(obj => obj.property_id == b_booking.property_id)
                if (bsIndex >= 0) {
                    b_slots[bsIndex] = sData
                } else {
                    b_slots.push(sData);
                }

                bookings_arr[index]['slots'] = b_slots;
            }
        }

        setBookings([...bookings_arr])
    }

    const handleSetPropertiesOrder = (properties_arr) => {
        // Sort in ascending order by sort_id and id
        properties_arr.sort((a, b) => {
            // If sort_order is equal, sort by id
            if (a.sort_order === b.sort_order) {
                return a.id - b.id;
            }
            // Otherwise, sort by sort_order
            return a.sort_order - b.sort_order;
        });
        return properties_arr
    }

    const handleSortBookings = (s_bookings = null) => {
        s_bookings = s_bookings || cloneDeep(bookings);

        s_bookings.sort((a, b) => {
            const a_booking = a.bookings[0] || null;
            const b_booking = b.bookings[0] || null;

            // First, compare by booking_date
            if (a_booking.booking_date > b_booking.booking_date) {
                return -1;
            } else if (a_booking.booking_date < b_booking.booking_date) {
                return 1;
            } else {
                // If booking_date is the same, compare by from_time
                if (a_booking.from_time > b_booking.from_time) {
                    return -1;
                } else if (a_booking.from_time < b_booking.from_time) {
                    return 1;
                } else {
                    return 0; // If both booking_date and from_time are equal
                }
            }
        });

        return s_bookings;
    }

    const handleSetBookableProperties = (properties_list) => {
        const iProperties = (properties_list) ? properties_list : properties;
        const bookable_services = iProperties.filter(obj => obj.property_type === "bookable-services");
        if (bookable_services.length > 0) {
            // sort_order in ascending order by sort_id and id
            bookable_services.sort((a, b) => {
                if (a.sort_order === b.sort_order) {
                    return a.id - b.id; // If sort_order is equal, sort by id
                }
                return a.sort_order - b.sort_order; // Otherwise, sort by sort_order
            });

            setBookableProperties(bookable_services)
            return bookable_services;
        }
    }

    const handleCheckMaxBookingsPerDayAllow = (booking_date, bIndex = null) => {
        // max_checkins
        let count = 0;
        if (bookingDetails.max_bookings_per_day && bookingDetails.max_bookings_per_day >= 1) {
            const bookings_data = [...bookings]
            bookings_data.forEach((booking, index) => {
                const bk = booking.bookings[0];
                if (bk && booking.booking_date == booking_date && index != bIndex) {
                    count++;
                }
            });
        }

        const mBookings_day = bookingDetails.max_bookings_per_day;
        return ((mBookings_day && mBookings_day == 0) || mBookings_day > count) ? true : false;
    }

    const handleCheckMaxCheckins = (bookings_arr = null, type = 'booking') => {
        // max_checkins
        let checkin = true;
        const max_checkins = {};
        if (bookingDetails.max_checkins && bookingDetails.max_checkins >= 1) {
            const bookings_data = bookings_arr || bookings
            bookings_data.forEach(booking => {
                const bAdmissions = booking.admissions;
                bAdmissions.forEach(adm => {
                    if (adm?.id) {
                        const allowed = adm?.requested_persons || adm?.allowed_person;
                        if (!max_checkins[adm.id]) {
                            max_checkins[adm.id] = allowed;
                        } else {
                            max_checkins[adm.id] = parseInt(max_checkins[adm.id]) + parseInt(allowed);
                        }
                    }
                });
            });

            // Loop over max_checkins
            for (const id in max_checkins) {
                if (max_checkins.hasOwnProperty(id)) {
                    const maxCount = max_checkins[id];
                    if (maxCount > bookingDetails.max_checkins) {
                        checkin = false
                        break;
                    }
                }
            }
        }

        return true;
        return (type == 'admission') ? max_checkins : checkin;
    }

    const handleSetBooking = (max_check = true) => {

        if (bookingDetails.max_bookings != 0 && bookings.filter(obj => obj.status != 5).length >= bookingDetails.max_bookings) {
            dispatch({type: "SHOW-ERROR", payload: 'Sorry, max booking limit reached.'})
            return false;
        }

        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            if (!booking.booking_date) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id) {
                    error = `"${b_booking.name}" not selected.`
                    errors.push(error)
                }

                if (!b_booking.from_time || !b_booking.to_time) {
                    error = `"${b_booking.name}" slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }

        bookings.map((booking, index) => {
            bookings[index]['selected'] = false
        });

        const bProperties = [];
        bookableProperties.map((p) => {
            let row = {
                bookable_service_id: null, from_time: null, to_time: null,
                property_id: p.id, name: p.name,
                expected_guests: 0, status: 1, change: false
            }

            bProperties.push(row)
        })

        const isAnyReqProperty = properties.find(obj => obj.slug == 'from_date' || obj.slug == 'max-bookings' || obj.slug == 'admissions-availability')

        const selected = (isAnyReqProperty) ? true : true;

        const bRow = {
            booking_date: '',
            slots: [],
            admissions: admissions,
            bookings: bProperties,
            status: 1,
            selected: selected
        }

        const mcBookings = cloneDeep(bookings)

        mcBookings.push(bRow)
        const max_checkin_res = (max_check) ? handleCheckMaxCheckins(mcBookings) : true;
        if (max_checkin_res) {
            bookings.unshift(bRow)
            setBookings([...bookings])
            setPreSelectedBooking(bRow)
        } else {
            dispatch({type: 'SHOW-ERROR', payload: 'Cannot add more bookings, Max checkin limit reached.'})
            return false;
        }

    }

    const handleValidateBooking = (b_index) => {
        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
            if (!booking.booking_date || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id) {
                    error = `"${b_booking.name}" not selected.`
                    errors.push(error)
                }

                if (!b_booking.from_time || !b_booking.to_time) {
                    error = `"${b_booking.name}" slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }

        return true;
    }

    const handleRemoveBooking = (b_index) => {
        let bClone = cloneDeep(bookings)
        const booking = bClone[b_index];

        Swal.fire({
            title: 'Alert!',
            html: "<span>Are you sure to delete this booking!</span>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                if (booking.id) {
                    handleDeleteBookings([booking.id]).then(res => {
                        if (res) {
                            bClone.splice(b_index, 1)
                            setBookings([...bClone])
                        }
                        return res;
                    })
                    // bClone[b_index]['status'] = 5;
                    // bClone[b_index]['selected'] = false;
                    // setBookings([...bClone])
                } else {
                    if (b_index == 0 && bookings.length === 1) {
                        booking['booking_date'] = '';
                        const b_bookings = booking.bookings
                        b_bookings.map((b_booking, b_index) => {
                            delete b_bookings[b_index].booking_id;
                            delete b_bookings[b_index].booking_date;
                            b_bookings[b_index]['booking_id'] = '';
                            b_bookings[b_index]['booking_date'] = '';
                            b_bookings[b_index]['from_time'] = '';
                            b_bookings[b_index]['to_time'] = '';
                            b_bookings[b_index]['bookable_service_id'] = '';
                        });

                        booking['selected'] = true
                        booking['bookings'] = b_bookings
                        bClone = [booking];
                    } else {
                        bClone.splice(b_index, 1)
                    }

                    setBookings([...bClone])
                    dispatch({type: 'SHOW-WARNING', payload: 'Bookings removed successfully.'})
                }
            }
        })
    }

    async function handleDeleteBookings(booking_id) {
        let apiUrl = Api.getApiURL();
        const params = {
            pcl_id: global.pcl.id,
            order_id: orderId,
            booking_id: booking_id
        }

        booking_id = (Array.isArray(booking_id) && booking_id.length > 0) ? booking_id[0] : booking_id;

        // Request to delete booking
        try {
            const res = await axios.delete(apiUrl + `client/bookings/${booking_id}`, {data: params});
            const data = res.data.data;
            if (data.errors) {
                dispatch({type: 'SHOW-SUCCESS', payload: Services.getErrorList(data.errors)})
            }else {
                dispatch({type: 'SHOW-SUCCESS', payload: 'Booking removed successfully.'})
            }
            return true;
        } catch (res) {
            console.error('Booking error:', res.response);
            dispatch({type: 'SHOW-ERROR', payload: Services.getErrorList(res.response.data?.data)})
            return false;
        }
    }

    const handleCancelBookingChanges = (b_index) => {
        const bClone = cloneDeep(bookings)
        bClone[b_index] = preSelectedBooking;
        bClone[b_index]['selected'] = false;
        setBookings([...bClone])
        setPreSelectedBooking({})
    }

    const handleSetEditBooking = (b_index) => {

        // check camp properties if exist
        const max_booking = properties.find(obj => obj.slug == 'max-bookings')
        const availability = properties.find(obj => obj.slug == 'admissions-availability')

        let max_bookings = null;
        let avail_weeks = null;

        // check and validate max_bookings value
        if (max_booking) {
            if (max_booking.value && max_booking.property_values) {
                const found = max_booking.property_values.find(obj => obj.id == max_booking.value)
                if (found) {
                    max_bookings = found.value
                    bookingDetails['max_bookings'] = max_bookings
                    setBookingDetails({...bookingDetails})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
                    return false;
                }
            } else {
                dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
                return false;
            }
        }

        let error = false;
        if (availability) {
            if (availability.value && availability.property_values) {
                const found = availability.property_values.find(obj => obj.id == availability.value)
                if (found) {
                    let avail_data = found.value
                    avail_data = avail_data.split(',');

                    if (avail_data.length == 2 || avail_data.length == 4) {
                        avail_weeks = avail_data.map((week) => week.trim());
                        avail_weeks.map((week, index) => {
                            if (index == 2 || index == 3) {
                                week = avail_weeks[0] + ' ' + week
                            }
                            const parsedDateTime = moment(week, undefined, true);
                            const valid = parsedDateTime.isValid();
                            if (!valid) {
                                error = true;
                            }
                        })

                        if (error) {
                            dispatch({
                                type: 'SHOW-ERROR',
                                payload: 'Sorry your date or time is incorrect, Please make sure you added correct date and time with correct format in ' + availability.name
                            })
                            return false;
                        }

                        if (avail_data[0] && avail_data[1]) {
                            bookingDetails['from_date'] = avail_data[0]
                            bookingDetails['to_date'] = avail_data[1]
                        }

                        if (avail_data[2] && avail_data[3]) {
                            bookingDetails['from_time'] = avail_data[2]
                            bookingDetails['to_time'] = avail_data[3]
                        }

                        // console.log(bookingDetails)
                        setBookingDetails({...bookingDetails})

                    } else {
                        error = true;
                    }

                } else {
                    error = true;
                }
            } else {
                error = true;
            }
        }

        if (error) {
            dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${availability.name}.`})
            return false;
        }

        // Check for booking from date
        let from_booking_date = null; //moment().format('YYYY-MM-DD')
        const from_date = properties.find(obj => obj.slug == 'from_date')
        if (from_date) {
            from_booking_date = (from_date.value) ? from_date.value : moment().format('YYYY-MM-DD');
            bookingDetails['booking_from_date'] = from_booking_date
            setBookingDetails({...bookingDetails})
            /*dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
            return false;*/
        }

        bookings[b_index]['selected'] = true;
        setBookings([...bookings])
        setPreSelectedBooking(bookings[b_index])
    }

    const handleCloseEdit = (b_index) => {
        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            if (!booking.booking_date) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id || !b_booking.from_time || !b_booking.to_time) {
                    error = `“${b_booking.name}” slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }


        if (!isValidBooking(bookings[b_index])) {
            dispatch({type: 'SHOW-WARNING', payload: 'Please select correct date and slots for booking.'})
            return false;
        }


        bookings[b_index]['selected'] = false;
        setBookings([...bookings])
    }

    const handleCancelEdit = (b_index) => {
        bookings[b_index]['selected'] = false;
        setBookings([...bookings])
    }

    const handleResetBooking = () => {

        const booking_ids = [];
        let bookings_copy = cloneDeep(bookings)
        const new_booking = bookings_copy[0] ? cloneDeep(bookings_copy[0]) : null;

        bookings_copy.map((booking, index) => {
            if (booking.id) {
                booking_ids.push(booking.id)
            } else {
                bookings_copy.splice(index, 1);
            }
        });

        if (new_booking) {
            new_booking['booking_date'] = '';
            const b_bookings = new_booking.bookings
            b_bookings.map((b_booking, b_index) => {
                delete b_bookings[b_index].booking_id;
                delete b_bookings[b_index].booking_date;
                b_bookings[b_index]['booking_id'] = '';
                b_bookings[b_index]['booking_date'] = '';
                b_bookings[b_index]['from_time'] = '';
                b_bookings[b_index]['to_time'] = '';
                b_bookings[b_index]['bookable_service_id'] = '';
            });

            delete new_booking.id;
            new_booking['selected'] = true
            new_booking['bookings'] = b_bookings
        }

        bookings_copy = [new_booking]
        if (booking_ids.length > 0) {
            handleDeleteBookings(booking_ids).then(res => {
                if (res) {
                    setBookings([...bookings_copy])
                    dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are removed successfully.'})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Sorry, booking not removed please try again.'})
                }
                return res;
            })
        } else {
            setBookings([...bookings_copy])
            dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are removed successfully.'})
        }
    }

    // handle property change
    async function handleChange(e, index, value, change_type = null) {
        // if (e) e.preventDefault();

        const property = cloneDeep(properties[index])
        const is_booking_set = bookings.find(obj => obj.id || (obj.booking_date && obj.booking_date !== ''))

        if (is_booking_set && (property.slug == 'from_date' || property.slug == 'max-bookings' || property.slug == 'admissions-availability')) {
            const result = await Swal.fire({
                title: 'Be Aware!',
                html: `<span>All bookings will be removed. Are you sure to change ${property.name}!</span>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#b35322',
                confirmButtonText: 'Yes'
            });

            if (!result.isConfirmed) {
                return false;
            } else {
                handleResetBooking();
            }
        }

        properties[index]['value'] = value;
        setProperties([...properties]);
        // setPropertyChange(true);

        // check and set max-bookings
        if (property.slug == 'max-bookings') {
            handlePropertyMaxBookings(property);
        } else if (property.slug == 'from_date') {
            const from_date = (property.value) ? property.value : moment().format('YYYY-MM-DD');
            setBookingFromDate(value)
            bookingDetails['from_date'] = from_date
            setBookingDetails({...bookingDetails})
            // handleResetBooking();
        }
        else if (property.slug == 'admissions-availability') {
            // handleResetBooking();
            let error = false;
            let avail_weeks = null;
            if (property && property.value && property.property_values) {
                const property_value = property.property_values.find(obj => obj.id == property.value)
                if (property_value) {
                    let avail_data = property_value.value
                    avail_data = avail_data.split(',');

                    if (avail_data.length == 2 || avail_data.length == 4) {
                        avail_weeks = avail_data.map((week) => week.trim());
                        avail_weeks.map((week, index) => {
                            error = false
                            if (index == 2 || index == 3) {
                                week = avail_weeks[0] + ' ' + week
                            }

                            const parsedDateTime = moment(week, undefined, true);
                            const valid = parsedDateTime.isValid();
                            if (!valid) {
                                error = true;
                            }
                        })

                        if (!error) {
                            if (avail_data[0] && avail_data[1]) {
                                bookingDetails['from_date'] = avail_data[0]
                                bookingDetails['to_date'] = avail_data[1]
                            }

                            if (avail_data[2] && avail_data[3]) {
                                bookingDetails['from_time'] = avail_data[2]
                                bookingDetails['to_time'] = avail_data[3]
                            }

                            // console.log(bookingDetails)
                            setBookingDetails({...bookingDetails})
                        }
                    }
                }
            }
        }
    }


    // handle property max bookings
    const handlePropertyMaxBookings = (property = null) => {

        // check camp properties if exist
        if (!property) {
            property = properties.find(obj => obj.slug == 'max-bookings')
        }

        let max_bookings = null;
        // check and validate max_bookings value
        if (property) {
            if (property.value && property.property_values) {
                const found = property.property_values.find(obj => obj.id == property.value)
                if (found) {
                    max_bookings = found.value
                    bookingDetails['max_bookings'] = max_bookings
                    setBookingDetails({...bookingDetails})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                    return false;
                }
            } else {
                dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                return false;
            }
        }

        return max_bookings;
    }

    // Load all bookable services slots
    const handleLoadSlots = (b_index, property_id, booking_date) => {

        const cl_bookings = cloneDeep(bookings)
        if (booking_date) {
            slotSearchTerms['booking_date'] = booking_date
            cl_bookings[b_index]['booking_date'] = booking_date;
            setBookings([...cl_bookings])
        }

        if (!booking_date && cl_bookings[b_index]['booking_date']) {
            slotSearchTerms['booking_date'] = cl_bookings[b_index]['booking_date']
        }

        if (!slotSearchTerms['booking_date'] || slotSearchTerms['booking_date'] === '') {
            dispatch({type: 'SHOW-ERROR', payload: 'Date must be selected from booking.'})
            return false;
        }

        if (bookingDetails && bookingDetails.from_time && bookingDetails.to_time) {
            slotSearchTerms['from_time'] = bookingDetails.from_time
            slotSearchTerms['to_time'] = bookingDetails.to_time
        }

        // return false

        /*if (bookable_service_id) {
            slotSearchTerms['bookable_service_id'] = bookable_service_id
        }*/

        slotSearchTerms['booking_id'] = cl_bookings[b_index].booking_id || '';
        if (property_id) slotSearchTerms['property_id'] = property_id;
        setSlotSearchTerms(slotSearchTerms);

        const qParams = "?" + new URLSearchParams(slotSearchTerms).toString();
        Api.get("client/bookable-service-times" + qParams, (res) => {
            const sData = res.data.data;
            sData['property_id'] = property_id;

            const b_slots = (cl_bookings[b_index] && cl_bookings[b_index].slots) ? cl_bookings[b_index].slots : [];
            const p_index = bookableProperties.findIndex(obj => obj.id == property_id)
            const bs_index = b_slots.findIndex(obj => obj.property_id == property_id)

            // console.log('cl_bookings[b_index].slots: ', cl_bookings[b_index].slots)
            // console.log('sData, bsIndex : ', bs_index, b_slots)
            // return false;

            if (bs_index <= 0) {
                cl_bookings[b_index]['slots'] = [sData];
            } else {
                b_slots[bs_index] = sData
                cl_bookings[b_index]['slots'] = b_slots;
            }

            // Remove selected slots
            const bb_bookings = [...cl_bookings[b_index].bookings];
            bb_bookings.map((b_booking, index) => {
                if ((p_index === 0) || (p_index > 0 && index >= p_index)) {
                    bb_bookings[index]['bookable_service_id'] = null
                    bb_bookings[index]['from_time'] = null
                    bb_bookings[index]['to_time'] = null
                }
            })

            cl_bookings[b_index]['bookings'] = bb_bookings
            setBookings([...cl_bookings])

        });

    }

    // Handle selected slot from calendar
    const handleSelectSlot = async (slot, bookable_service_id, property_id, b_index) => {

        let from_time = slot.from_time;
        let to_time = slot.to_time;

        const booking = cloneDeep(bookings[b_index])
        const sBookings = cloneDeep(booking.bookings) || []
        let booking_date = moment(booking.booking_date).format('YYYY-MM-DD');

        let bb_index = sBookings.findIndex(obj => obj.property_id == property_id)
        const sBooking = sBookings[bb_index] || null
        if (sBookings.length === 0 || !sBooking || bb_index === -1) {
            dispatch({
                type: 'SHOW-ERROR',
                payload: `Sorry selected booking not found, Try again. ${bb_index}, ${sBookings}, ${sBooking}`
            })
            return false;
        }

        const max_booking_day = handleCheckMaxBookingsPerDayAllow(booking_date, b_index)
        if (!max_booking_day) {
            dispatch({
                type: 'SHOW-ERROR',
                payload: 'Cannot more bookings on this date, Max bookings limit per day reached.'
            })
            return false;
        }

        sBooking['bookable_service_id'] = bookable_service_id;
        sBooking['from_time'] = from_time;
        sBooking['to_time'] = to_time;
        sBookings[bb_index] = sBooking;
        // booking['bookings'] = sBookings
        // bookings[b_index] = booking;
        // setBookings([...bookings]);

        let pre_from_time = from_time
        let pre_to_time = to_time
        const b_bookings = bookings[b_index].bookings

        let booking_slot_error = false;
        for (const [bb_index, b_booking] of sBookings.entries()) {
            if (bb_index !== 0) {
                const property = bookableProperties.find(obj => obj.id == b_booking.property_id)
                // console.log('bb_index, booking: ', bb_index, b_booking)
                const property_id = property.id;
                const booking_gap = property.booking_gap;
                const ba_services = property.bookable_services;

                let space_slot_error = false;
                let shouldBreakSpacesLoop = false;
                spacesLoop: for (const [s_index, space] of ba_services.entries()) {
                    const space_id = space.value;
                    const params = {
                        pcl_id: global.pcl.id,
                        property_id: property_id,
                        bookable_service_id: space_id,
                        booking_date: booking_date
                    }

                    if (bookingDetails && bookingDetails.from_time && bookingDetails.to_time) {
                        params['from_time'] = bookingDetails.from_time
                        params['to_time'] = bookingDetails.to_time
                    }

                    let slot_found = false;
                    let apiUrl = Api.getApiURL();
                    const qParams = "?" + new URLSearchParams(params).toString();

                    // Request to get spaces slots
                    const res = await axios.get(apiUrl + "client/bookable-service-times" + qParams);
                    const sData = res.data.data;
                    sData['property_id'] = property_id;
                    // console.log('sData: ', sData)
                    const b_slots = bookings[b_index].slots || []

                    const bsIndex = b_slots.findIndex(obj => obj.property_id == property_id)
                    if (bsIndex >= 0) {
                        b_slots[bsIndex] = sData
                    } else {
                        b_slots.push(sData);
                    }

                    const bs_index = sData.slots.findIndex(obj => obj.id == space_id)
                    const s_slots = (bs_index >= 0) ? sData.slots[bs_index].times || [] : []

                    const pre_index = ((bb_index - 1) >= 0) ? bb_index - 1 : 0
                    let new_to_time = sBookings[pre_index].to_time;
                    for (const slot of s_slots) {
                        if (booking_gap) {
                            new_to_time = booking_gap > 0
                                ? moment(pre_to_time, 'HH:mm').add(booking_gap, 'minutes').format('HH:mm')
                                : moment(pre_to_time, 'HH:mm').subtract(Math.abs(booking_gap), 'minutes').format('HH:mm');
                        }

                        // For private bookable service
                        if (slot.from_time >= new_to_time && space.private_space && slot.total_bookings == 0) {
                            b_booking['bookable_service_id'] = space_id
                            b_booking['from_time'] = slot.from_time
                            b_booking['to_time'] = slot.to_time
                            slot_found = true;
                            break;
                        }
                            // For shared bookable service
                        //  && slot.expected_guests <= space.capacity
                        else if (!space.private_space && slot.from_time >= new_to_time) {
                            b_booking['bookable_service_id'] = space_id
                            b_booking['from_time'] = slot.from_time
                            b_booking['to_time'] = slot.to_time
                            slot_found = true;
                            break;
                        }
                    }

                    if (slot_found) {
                        space_slot_error = false;
                        sBookings[bb_index] = b_booking
                        bookings[b_index]['slots'] = b_slots;
                        // setBookings([...bookings])
                        break;
                    } else {
                        space_slot_error = true;
                    }

                } // End of spaces loop

                if (space_slot_error) {
                    booking_slot_error = true;
                    // dispatch({type: 'SHOW-ERROR', payload: 'Next slots not available, Please select another slot.'})
                    break;
                }

            } // End of (bb_index !== 0) if condition
        } // End of booking loop

        if (!booking_slot_error) {
            bookings[b_index]['bookings'] = sBookings;
            setBookings([...bookings])
        } else {

            const booking_clone = cloneDeep(bookings)
            const bb_bookings = [...booking_clone[b_index].bookings];
            bb_bookings.map((booking, index) => {
                bb_bookings[index]['bookable_service_id'] = null
                bb_bookings[index]['from_time'] = null
                bb_bookings[index]['to_time'] = null
            })

            //booking_clone[b_index]['slots'] = []
            booking_clone[b_index]['bookings'] = bb_bookings
            setBookings([...booking_clone])

            dispatch({type: 'SHOW-ERROR', payload: 'Next slots not available, Please select another slot.'})
            return false;
        }
    }

    const handleBookingChange = (b_index, field_name, value, admission_id = null) => {
        const c_bookings = cloneDeep(bookings);
        if (field_name == 'admission') {
            const c_admissions = cloneDeep(c_bookings[b_index].admissions);
            const a_index = c_admissions.findIndex(obj => obj.id == admission_id)
            if (a_index >= 0) {
                const admission = c_admissions[a_index];
                if (value > 0 && value <= admission.max_additional_persons) {
                    // c_admissions[a_index]['additional_persons'] = value;
                    c_admissions[a_index]['requested_persons'] = parseInt(admission.allowed_person) + parseInt(value);
                    const max_checkins = handleCheckMaxCheckins(null, 'admission');
                } else if (value === 0 || value == '' || value == ' ') {
                    // c_admissions[a_index]['additional_persons'] = 0;
                    c_admissions[a_index]['requested_persons'] = 0;
                }

                c_bookings[b_index]['admissions'] = c_admissions;
                setBookings([...c_bookings])
            }
        }
        // Booking date change
        else if (field_name === 'booking_date') {
            const booking_date = moment(value).format('YYYY-MM-DD')

            const bb_bookings = [...c_bookings[b_index].bookings];
            bb_bookings.map((booking, index) => {
                bb_bookings[index]['bookable_service_id'] = null
                bb_bookings[index]['from_time'] = null
                bb_bookings[index]['to_time'] = null
            })

            c_bookings[b_index]['booking_date'] = booking_date
            c_bookings[b_index]['slots'] = []
            c_bookings[b_index]['bookings'] = bb_bookings
            setBookings([...c_bookings])
        }
    }

    const handleBookingDateChange = (b_index, property_id, date) => {
        const c_bookings = cloneDeep(bookings);

        date = new Date(date)
        // date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        // let booking_date = date.getUTCFullYear() + '-' + date.getUTCMonth() + '-' + date.getUTCDate();

        let booking_date = moment(date).format('YYYY-MM-DD');

        // booking_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        booking_date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');

        const bb_bookings = [...c_bookings[b_index].bookings];
        bb_bookings.map((booking, index) => {
            bb_bookings[index]['bookable_service_id'] = null
            bb_bookings[index]['from_time'] = null
            bb_bookings[index]['to_time'] = null
        })

        c_bookings[b_index]['booking_date'] = booking_date
        c_bookings[b_index]['slots'] = []
        c_bookings[b_index]['bookings'] = bb_bookings
        setBookings([...c_bookings])

        console.log('property_id: ', property_id)
        // Load slots for all bookable services
        handleLoadSlots(b_index, property_id, booking_date)
    }

    const handleReserveBooking = (b_index) => {
        // e.preventDefault()

        const isValidBooking = handleValidateBooking(b_index);
        if (!isValidBooking) {
            return false
        }

        // return false;

        const params = {}
        params['pcl_id'] = global.pcl.id
        params['item_id'] = itemId
        params['order_id'] = global.order_id || ''
        params['order_detail_id'] = orderDetailId || ''

        const bookings_data = cloneDeep(bookings[b_index]);
        const bookings_arr = bookings_data.bookings
        const admissions_arr = bookings_data.admissions

        const admissions_data = []
        if (bookings_arr.length > 0) {
            admissions_arr.map(admission => {
                // console.log('admission.requested_persons: ', admission.requested_persons)
                const param_adm = {
                    admission_id: admission.id,
                    requested_persons: admission.requested_persons || admission.allowed_person,
                }
                admissions_data.push(param_adm)
            })
        }

        bookings_data['admissions'] = (admissions_data.length > 0) ? admissions_data : admissions_arr;
        params['booking'] = bookings_data
        // console.log(bookings_data)

        // Update bookings
        if (orderDetailId && bookings_data.id) {
            const booking_id = bookings_data.id;
            Api.put(`client/bookings/${booking_id}`, params, (res) => {
                const data = res.data.data
                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                // window.location.assign('/cart')

            }, (errors) => {
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }

        // Add/Reserve new booking
        else {
            // create new bookings
            Api.post(`client/bookings`, params, (res) => {
                const data = res.data.data
                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})

            }, (errors) => {
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }
    }

    // Handle update booking
    const handleUpdateBooking = (b_index) => {
        const params = {}
        params['pcl_id'] = global.pcl.id
        params['item_id'] = itemId
        params['order_id'] = global.order_id || ''
        params['order_detail_id'] = orderDetailId || ''

        const is_booking_item = (bookableProperties.length > 0);
        const bookings_data = cloneDeep(bookings[b_index]);
        const bookings_arr = bookings_data.bookings
        const admissions_arr = bookings_data.admissions

        const admissions_data = []
        if (is_booking_item && bookings_arr.length > 0) {
            admissions_arr.map(admission => {
                // console.log('admission.requested_persons: ', admission.requested_persons)
                const param_adm = {
                    admission_id: admission.id,
                    requested_persons: admission.requested_persons || admission.allowed_person,
                }
                admissions_data.push(param_adm)
            })
        }

        bookings_data['admissions'] = (admissions_data.length > 0) ? admissions_data : admissions_arr;
        params['booking'] = bookings_data

        if (!isValidBooking(bookings_data)) {
            dispatch({type: "SHOW-ERROR", payload: 'Enter select valid date and slots for booking.'});
            return false;
        }

        const b_count = validateBookingsCount([bookings_data])
        if (is_booking_item && b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        // return false;
        if (orderDetailId && bookings_data.id) {
            const booking_id = bookings_data.id;
            Api.put(`client/bookings/${booking_id}`, params, (res) => {
                const data = res.data.data
                // setCartData(data.order_details)

                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])

                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                // window.location.assign('/cart')

            }, (errors) => {
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            })
        }
        else {
            params['request_type'] = 'add-bookings'
            // create new bookings
            Api.post(`client/bookings`, params, (res) => {
                const data = res.data.data
                //setCartData(data.order_details)

                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])

                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})

            }, (errors) => {
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }
    }

    const handleMonthChange = (date, type = null) => {
        date = moment(date).format('YYYY-MM-DD')
        handleCheckAvailability(date)
    }

    const isDateAvailable = (date) => {
        const today = moment().add(bookingDetails.advance_booking_days || 0, "days");
        // Check if the date is before from selected date then day will be unavailable
        console.log(moment(date).format('YYYY-MM-DD'), '<===>', bookingFromDate);
        if (bookingFromDate && moment(date).isBefore(bookingFromDate, 'day')) {
            console.log('<------------------------------------------->')
            return false;
        }

        // Check if the date is greater than today's date
        if ((moment(date).isSameOrAfter(today, 'day')) && availableDates.some(availableDate => moment(date).isSame(availableDate, 'day'))) {
            return true;
        }
        // Check if the date is in the list of available dates
        // return ;
    }

    const dependency_check = (property) => {
        let dependency = true;
        if (property.depends_on_property) {
            dependency = false;
            const parentProp = properties.find(p => p.id === property.depends_on_property.depends_on_property_id);

            if (parentProp && property.depends_on_property.depends_on_property_value == parentProp.value) {
                dependency = dependency_check(parentProp); //Nested call
            }
        }

        return dependency;
    }

    let isBookingSelected = bookings.findIndex(obj => obj.selected === true);

    const from_date_pro = properties.find(obj => obj.slug === 'from_date')
    const max_bookings_pro = properties.find(obj => obj.slug === 'max-bookings')
    const admissions_avail_pro = properties.find(obj => obj.slug === 'admissions-availability')
    const isBookingEditable = ((from_date_pro && !from_date_pro.value) || (max_bookings_pro && !max_bookings_pro.value) || (admissions_avail_pro && !admissions_avail_pro.value)) ? false : true;

    return (
        <>
            {/* Bookings Details */}
            {(bookableService) &&
                <Row className=''>

                    {/* To show list of bookings */}
                    <Col md={12}>
                        <div>
                            {bookings.map((booking, index) => {
                                if (booking.selected || booking.status == 5) return null;
                                let s_slot = {}
                                const b_bookings = booking.bookings;
                                const b_admissions = booking.admissions;
                                return (
                                    <Col md={12}>
                                        <div key={index + 20}
                                             className={`booking-short-view d-flex flex-wrap justify-content-between align-items-center mb-2`}
                                             style={{padding: '10px', backgroundColor: 'lightgray'}}>

                                            {/* Booking date */}
                                            <div className=''>
                                                <span>Booking Date: </span>
                                                <span className='fw-bold'>{(booking.booking_date) ? moment(booking.booking_date).format('DD-MM-YYYY') : '-'}</span>
                                            </div>

                                            {/* Selection booking space */}
                                            {b_bookings.map((b_booking, b_index) => {
                                                s_slot['from_time'] = booking.from_time
                                                s_slot['to_time'] = booking.to_time
                                                const property = bookableProperties.find(obj => obj.id == b_booking.property_id)
                                                const bServices = (property) ? property.bookable_services : [];
                                                const space = bServices.find(obj => obj.value == b_booking.bookable_service_id) || {}
                                                return (
                                                    <div className=''>
                                                        <span>{b_booking.name}: </span>
                                                        <span className='fw-bold me-1'>({(space.name) && space.name + ':'}</span>
                                                        <span className='fw-bold'>{(b_booking.from_time) ? moment(b_booking.from_time, 'HH:mm').format('hh:mma') : '-'}
                                                            -
                                                            {(b_booking.to_time) && moment(b_booking.to_time, 'HH:mm').format('hh:mma')})
                                                                                    </span>
                                                    </div>
                                                )
                                            })
                                            }

                                            <div className=' text-end mt-2'>
                                                {(!bookings.find(b => b.selected === true)) &&
                                                    <>
                                                        {(bookings.length > 1) &&
                                                            <button type='button'
                                                                    className='btn btn-sm btn-danger me-1'
                                                                    onClick={(e) => handleRemoveBooking(index)}>
                                                                <XCircleFill/>
                                                            </button>
                                                        }

                                                        <button type='button'
                                                                className='btn btn-sm btn-success me-2'
                                                                onClick={(e) => handleSetEditBooking(index)}>
                                                            <PencilSquare/>
                                                        </button>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </Col>
                                )
                            })
                            }
                        </div>
                    </Col>

                    {/* Add/Edit bookings section */}
                    {bookings.map((booking, index) => {

                        if (!booking.selected || !isBookingEditable) return null;

                        let s_slot = {}
                        const b_slots = booking.slots || [];
                        const b_bookings = booking.bookings;
                        const b_admissions = booking.admissions;
                        const property_id = b_bookings[0]?.property_id || null;

                        return (
                            <Col md={12}>
                                <div key={index + 20}
                                     className={`bookings-list ${(booking.booking_date) ? 'pb-2' : 'pb-2'}`}>

                                    {/* Booking date */}
                                    <div className='mt-3 d-flex justify-content-center'>
                                        <div className="form-group">
                                            <div className='text-center fs-17x fw-bold text-dark-light'>Select Booking Date</div>

                                            <div className="custom-date-picker-container">
                                                <DatePicker
                                                    className="form-control custom-date-picker-input w-100"
                                                    dateFormat="yyyy-MM-dd"
                                                    filterDate={isDateAvailable}
                                                    selected={booking.booking_date}
                                                    onMonthChange={handleMonthChange}
                                                    onCalendarOpen={() => handleMonthChange(booking.booking_date || moment())}
                                                    onChange={(date) => handleBookingDateChange(index, property_id, date)}
                                                    // onChange={}
                                                />

                                                <CalendarMinus
                                                    className="custom-date-picker-icon"
                                                    onClick={() => document.querySelector('.custom-date-picker-input').focus()}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    {(booking.booking_date) &&
                                        <>
                                            {/* Bookings Admissions Details */}
                                            <div>
                                                <div
                                                    className='section-heading-1 mt-4 fs-18x text-center text-dark-light'>Admissions:
                                                </div>
                                                <div
                                                    className='d-flex gap-2 flex-wrap mt-2 justify-content-center'>
                                                    <div className='fw-bold fs-18x'>Included
                                                        :
                                                    </div>
                                                    <div className='d-flex gap-2'>
                                                        {b_admissions.map((adm, idx) => {
                                                            return (
                                                                <div key={idx}>{adm.name}: {adm.allowed_person}</div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                {/* Additional Admissions */}
                                                <div
                                                    className='d-flex mt-2 gap-2 flex-wrap justify-content-center'>
                                                    {b_admissions.map((adm, idx) => {
                                                        if (adm.max_additional_persons > 0) {
                                                            const additional_person = (adm.requested_persons >= 0) ? adm.requested_persons - adm.allowed_person : adm.additional_persons || 0;
                                                            return (
                                                                <div key={idx} className='me-2'>
                                                                    <label>Add Additional {adm.name.slice(0, 8)}</label>
                                                                    <select className='form-select' style={{maxWidth: '183px'}} value={additional_person}
                                                                        onChange={(e) => handleBookingChange(index, 'admission', e.target.value, adm.id)}>
                                                                        <option value=''>0</option>
                                                                        {[...Array(adm.max_additional_persons + 1).keys()].slice(1).map(i => (
                                                                            <option key={i} value={i}>{i} (${adm.price_per_person * i})</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            {/* Admissions end */}

                                            {/* Slots colors description */}
                                            <div className='d-flex gap-3 mt-2 flex-wrap'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='desc-color-box-available me-2'></div>
                                                    <div className=''>Available</div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='desc-color-box-selected me-2'></div>
                                                    <div className=''>Selected</div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='desc-color-box-unavailable me-2'></div>
                                                    <div className=''>Unavailable</div>
                                                </div>
                                            </div>
                                            {/* END Slots colors description */}

                                            {/* Booking space selection */}
                                            <div className='d-flex gap-3 flex-wrap mt-2'>
                                                {b_bookings.map((b_booking, b_index) => {
                                                    const bService_id = b_booking.bookable_service_id;
                                                    s_slot['from_time'] = b_booking.from_time
                                                    s_slot['to_time'] = b_booking.to_time
                                                    const property = bookableProperties.find(obj => obj.id == b_booking.property_id)

                                                    const as_combine = false; /*(property) ? property.as_combine : false*/
                                                    const bServices = (property) ? property.bookable_services : [];
                                                    return (
                                                        <div key={b_index} className='space-item d-flex justify-content-center'>
                                                            <div className='w-100'>

                                                                {/* Bookable services */}
                                                                <div className='mt-3'>
                                                                    <div className='fw-bold mb-1 bookable-property-title'>{b_booking.name}</div>

                                                                    <div className=''>
                                                                        {bServices.map((service, ind) => {
                                                                            const bookable_service_id = service.value

                                                                            let images = service.images || null;
                                                                            let space_slots = [];
                                                                            const booking_slots = booking.slots
                                                                            const property_slots = booking_slots.find(obj => obj.property_id == property.id)
                                                                            if (property_slots) {
                                                                                const slots_spaces = property_slots.slots || []
                                                                                const space_found = slots_spaces.find(obj => obj.id == bookable_service_id);
                                                                                space_slots = (space_found) ? space_found.times : []
                                                                            }

                                                                            console.log('service', service)
                                                                            return (
                                                                                <div
                                                                                    className='d-flex align-items-center gap-3 mb-3 bookable-service-section flex-wrap'>
                                                                                    <div className='bookable-service-cart bs-list-item1 '>
                                                                                        <div style={{width: '117px'}}>
                                                                                            <div className='bookable-space-img'>
                                                                                                <img style={{maxWidth: '100%'}} src={(images && images.length > 0) ? images[0].url : room_default_img} alt=''/>
                                                                                            </div>
                                                                                            <div key={ind} className={`bookable-space ${(bookable_service_id == bService_id) && 'active'}`}>
                                                                                                <div>{service.name}</div>
                                                                                                {(service.price != null && service.price != 0) &&
                                                                                                    <div className='fs-13x text-light'>{(service.price > 0) ? 'Extra ' : 'Disc '} (${service.price})</div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Bookable service slots */}
                                                                                    <div className='bs-list-item2'>
                                                                                        {/* Slots selection */}
                                                                                        <div className='d-flex mt-1 gap-2 flex-wrap slots-list'>
                                                                                            {/* Check if not any slot */}
                                                                                            {(space_slots.length === 0) &&
                                                                                                <div className='mt-2'>Booking slot available.</div>
                                                                                            }

                                                                                            {space_slots.map((slot, sIndex) => {
                                                                                                //const time_slot = {from_time: time.from_time, to_time: time.to_time}
                                                                                                const active_slot = (bService_id == bookable_service_id && s_slot.from_time >= slot.from_time && s_slot.to_time <= slot.to_time)
                                                                                                const slot_available = slot.available

                                                                                                const slot_time = moment(slot.from_time, 'HH:mm').format('hh:mma') + ' - ' + moment(slot.to_time, 'HH:mm').format('hh:mma')

                                                                                                return (
                                                                                                    <div key={sIndex} data-tooltip-id='tooltip' data-tooltip-content={slot_time}
                                                                                                        className={`space-slot ${(active_slot) && 'active'} ${(!slot_available) && 'disabled'}`}
                                                                                                        onClick={() => {
                                                                                                            (b_index === 0 && slot_available) ?
                                                                                                                handleSelectSlot(slot, bookable_service_id, b_booking.property_id, index)
                                                                                                                :
                                                                                                                (slot_available) ?
                                                                                                                    dispatch({
                                                                                                                        type: 'SHOW-ERROR',
                                                                                                                        payload: `Sorry, you can only chose ${b_bookings[0].name} slot and next available slots will be auto select.`
                                                                                                                    })
                                                                                                                    :
                                                                                                                    dispatch({type: 'SHOW-ERROR', payload: `Sorry, selected slot not available.`})
                                                                                                        }}>
                                                                                                        {moment(slot.from_time, 'HH:mm').format('hh:mm a')}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                        </>
                                    }


                                    {/* Remove bookings buttons */}
                                    <div className='mt-5 text-center mb-2'>
                                        {(booking.selected && orderDetailId) &&
                                            <button type='button'
                                                    className='btn btn-sm btn-primary me-2'
                                                    onClick={(e) => handleUpdateBooking(index)}>
                                                Save Booking Changes
                                            </button>
                                        }

                                        {(booking.selected && !orderDetailId) &&
                                            <>
                                                <button type='button'
                                                        className='btn btn-sm btn-primary me-2'
                                                        onClick={(e) => handleCloseEdit(index)}>
                                                    Update Booking
                                                </button>

                                                {/*<button type='button' className='btn btn-sm btn-warning me-2' onClick={(e) => handleCancelEdit(index)}>
                                                                                Cancel Edit
                                                                            </button>*/}
                                            </>
                                        }

                                        {(orderDetailId && booking.id) &&
                                            <button type='button'
                                                    className='btn btn-sm btn-warning me-2'
                                                    onClick={(e) => handleCancelBookingChanges(index)}>
                                                Cancel Changes
                                            </button>
                                        }

                                        {(bookings.length > 1) &&
                                            <button type='button'
                                                    className='btn btn-sm btn-danger'
                                                    onClick={(e) => handleRemoveBooking(index)}>
                                                <XCircleFill/>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                    }

                    {/* Show this table if no any booking found */}
                    {(bookings.length === 0) &&
                        <div className='bookings-list'>
                            <div className='text-center'>Click on add booking to reserve slot</div>
                        </div>
                    }
                </Row>
            }

            {(bookingErrors.length > 0) &&
                <Alert variant="danger" onClose={() => setBookingErrors([])} dismissible>
                    <Alert.Heading>Booking errors!</Alert.Heading>
                    <p>{Services.getErrorList(bookingErrors)}</p>
                </Alert>
            }

            {/* Add new booking button  */}
            {(isBookingSelected === -1 && bookableProperties.length > 0 && bookingDetails.max_bookings != 1 && handleCheckMaxCheckins(bookings)) &&
                <div className='d-flex justify-content-center'>
                    <button type='button' className='btn btn-success' onClick={() => handleSetBooking()}>
                        <Plus size={16}/> Add New Booking
                    </button>
                </div>
            }
        </>
    )
}